.head {
  $back-button-width: 60px;
  $label-width: 240px;
  $selected-items-width: 400px;
  $meta-data-font-size-sm: 10px;

  display: flex;
  margin: 36px 0 30px;
  padding-bottom: 24px;
  background-color: #fff;
  z-index: 100;
  // border-bottom: 1px dashed #7c98b6;
  border-bottom: 1px dashed rgb(211, 211, 211);
  justify-content: space-between;

  .meta-data-container {
    // color: #0c131a;
    // font-size: 12px;
  }

  .catalog-exchange-header {
    display: flex;
    justify-content: space-between;
    // flex: 1;
    // flex-basis: calc(#{$selected-items-width} + #{$label-width});

    h2 {
      margin-right: 16px;
    }
  }

  .catalog-item-meta {
    flex: 1;
  }

  .back-button,
  .close-button {
    display: flex;
    // align-items: center;
    // height: 44px;

    svg {
      cursor: pointer;
      transition: 0.3s;
      height: 24px;
    }
  }

  .back-button {
    svg {
      opacity: 0;
      pointer-events: none;
      transform: scale(0.6);
      margin-right: 16px;
    }

    &.show svg {
      opacity: 1;
      transform: scale(1);
      pointer-events: unset;
    }
  }

  .close-button {
    svg {
      margin-left: 16px;
    }
    // min-height: 32px;
  }

  .modal-product-layout {
    // width: 100%;
    display: flex;
    transition: 0.3s;
    border-left: 1px solid #dee2e6;
    // padding-left: 15px;
    // margin-right: 30px;
    // transform: translateX(-$back-button-width);
    // justify-content: flex-end;
    // $label-width: 15px;
    // $selected-items-width: 200px;

    // flex-basis: calc(#{$selected-items-width} + #{$label-width});

    [class*='col'] {
      padding: 0;
    }

    &.glide-right {
      // transform: translateX(0);
    }

    .details-col {
      // flex-basis: 150px;
      margin-left: 5px;

      & .title {
        // max-width: 400px;
        // font-size: 14px;
        // line-height: 1.43;
        // margin-bottom: 5px;
        // margin-bottom: 0;
        // color: #001234;
        max-width: 260px;
        // padding-right: 40px;
      }
      & .price {
        font-size: 14px;
        line-height: 1.43;
        color: #7c98b6;
      }
    }
    .image-col {
      // padding-right: 20px;
      display: inline-block;

      img {
        height: 60px;
        border-radius: 3px;
      }
    }

    .selected-item-label {
      font-size: 12px;
      margin: 0 16px;
      // flex-basis: auto;
      // flex-basis: $label-width;
    }

    .catalog-selected-item {
      display: flex;
      // margin: 0 0 0 10px;
      // flex: 1;
      // flex-basis: $selected-items-width;
    }
  }

  @media screen and (max-width: 700px) {
    padding: 20px 15px;
    margin: 0;

    .catalog-exchange-header {
      justify-content: center;
      flex-wrap: wrap;
    }

    .back-button,
    .close-button {
      // position: absolute;
      top: 20px;
      z-index: 1;

      svg {
        transition: 0.2s;
        margin: 0;
      }
    }

    .back-button {
      left: 15px;

      svg {
        // margin: 0 10px 0 0;
      }
    }

    .close-button {
      right: 15px;
    }

    .modal-product-layout {
      padding: 10px 0 0;
      transform: translateX(0);
      border: none;
      margin: 0 !important;

      & .details-col {
        & .title {
          max-width: 178px;
          // padding-right: 20px;
        }
      }
      .catalog-selected-item {
        flex-basis: auto;
      }
      .selected-item-label {
        font-size: $meta-data-font-size-sm;
        margin: 0 7px 0 0;
        // flex-basis: 130px;
      }
    }

    .meta-data-container {
      font-size: $meta-data-font-size-sm !important;
    }
  }

  @media screen and (max-width: $break-small) {
    position: sticky;
    top: 0;
    width: 100vw;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    border-bottom: none;

    .modal-product-layout {
      & .details-col {
        & .title {
          margin-bottom: 2px;
        }
      }
    }
  }
}
