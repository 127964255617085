.pickupScheduler__cancelPickupModal {
  max-width: 600px;

  .modal-content {
    border: none;
    padding: 25px 32px;
  }
  &__header {
    border: none;
    padding: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .modal-title {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #33475b;
    }

    button {
      outline: none;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;

    .info-icon {
      width: 40px;
      margin-right: 22px;

      svg {
        transform: translateY(5px);
      }
    }

    &--brief {
      color: #475768;

      strong {
        font-weight: 700;
      }
    }

    &--extraInfo {
      margin-top: 15px;
      font-size: 15px;
    }
  }

  &__actions {
    text-align: right;
    margin-top: 20px;
    button {
      border: none;
      color: #bf5b5b;
      background-color: #ffd6d6;
      padding: 10px 50px;
      border-radius: 5px;
      outline: none;
      cursor: pointer;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }

  @media (max-width: 576px) {
    // top: 100vh;
    margin: 0;
    .modal-content {
      margin: 0;
      border-radius: 0;
    }
    &__content {
      &--extraInfo {
        margin-left: -62px;
      }
    }
    &__actions {
      button {
        padding: 10px 30px;
      }
    }
  }
}

// .pickupScheduler__cancelPickupModalWrapper {
//   @media (max-width: 576px) {
//     &.modal.show > .pickupScheduler__cancelPickupModal {
//       opacity: 0;
//       animation: mobile-glide-in 0.3s ease-out forwards;
//     }
//   }
//   @media (min-width: 576px) {
//     &.modal.show > .pickupScheduler__cancelPickupModal {
//       opacity: 0;
//       animation: web-glide-in 0.3s ease-out forwards;
//     }
//   }
// }

// @keyframes mobile-glide-in {
//   from {
//     opacity: 0;
//     transform: translateY(0);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(-100%);
//   }
// }

// @keyframes web-glide-in {
//   from {
//     opacity: 0;
//     transform: translateY(-100%);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }
