.service-list {
  text-align: left;
  padding-bottom: 80px;

  .service-list-item {
    padding: 15px 20px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.19);
    box-shadow: rgba(17, 17, 26, 0.1) 0px 2px 16px,
      rgba(17, 17, 26, 0.05) 0px 4px 32px;

    background-color: #fff;

    &:not(:first-child) {
      margin-top: 18px;
    }

    &.disabled {
      cursor: not-allowed;

      & > div {
        pointer-events: none;
      }

      .option-icon i {
        background-color: rgba(189, 198, 207, 0.4);
      }

      &:hover {
        .option-icon i {
          transform: scale(0.6);
        }
      }
    }

    & > div {
      &.service-list-item-type {
        cursor: pointer;
        display: flex;

        .service-heading {
          display: flex;
          flex-direction: column;
          // margin-bottom: 4px;
        }

        .service-title {
          // margin-top: 3px;
          // font-size: 16px;
          // color: #636e79;
          // font-weight: bold;
          text-transform: uppercase;
        }

        .service-description {
          font-size: 12px;
          color: #636e79;
        }
      }

      &.error-list .alert {
        margin: 0;
        margin-top: 16px;
      }

      .option-icon i {
        border-radius: 50%;
        transform: scale(0.9);
        transition: 0.2s;
      }

      &:hover {
        .option-icon:not(.selected) i {
          color: var(--theme__primary--color, $primaryColor);
        }
      }
    }

    .option-icon {
      margin-right: 10px;
    }

    @import 'select-variant';
  }
}

// @keyframes grow-n-shrink {
//   0% {
//     transform: scale(0.9);
//   }
//   50% {
//     transform: scale(1.1);
//   }
// }
