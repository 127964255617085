$break-small: 480px;

@import '../mixins';

.smalltext {
  font-size: 14px;
}
.zero-padding {
  padding: 0px;
}
body {
  font-family: var(--theme__font-family, 'Inter UI', sans-serif) !important;
  background: #fcfdff;
  overflow-x: hidden;
}
body.login {
  background: url('../images/bg.jpg');
  background-size: cover;
  background-position: left top;
}
// b,
// strong {
//   font-weight: 500;
// }
#header {
  z-index: 1020;
  position: sticky;
  top: 0px;
  width: 100%;
}
#fixedOnlineAlert {
  z-index: 100000;
  text-align: center;
  position: fixed;
  width: 90%;
  top: 4.54rem;
  max-width: 600px;
  left: 0;
  right: 0;
  box-shadow: inset 0 -2px 0 0 #edec88;
  background-color: #fffcc2;
  margin: auto;
  font-size: 15px;
  /*font-weight: 600;*/
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #75731e;
  border-color: #edec88;
}
.delivery-card.list-mode .item-subpanel .status-map {
  display: block;
}
.order-link {
  display: block;
  color: #2c405a;
  background: #fafbfc;
}
.order-link:hover {
  text-decoration: none;
  color: #2c405a;
}

.order-link.is-selected {
  background: #ffffff;
}

/*Confirmation screen
#confirmation .item .item-details .item-credentials .item-name {
  font-size: 14px;
}
#confirmation .info-group .subhead {
  font-size: 16px;
  font-weight: 500;
}
*/

.heading {
  background: #fff;
  border-bottom: 1px solid #eee;
  height: 60px;
  line-height: 60px;
  padding: 0 10px;
}

::-webkit-scrollbar {
  width: 4px;
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background: var(--theme__primary--color, #333);
  @media screen and (max-width: $break-small) {
    background-color: none;
  }
}

.clearfix {
  display: block;
}

.clearfix::after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/*confirmed*/

/* Page spineer */
.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce1 {
  background: #516f90;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgb(0, 18, 52);
}

.Loader__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: $headerMargin;

  &.compact {
    min-height: unset;
    padding: 0;
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*address and details screen*/
#details-row > .form-group > .card {
  min-height: 200px;
}
#details-row > .form-group .form-check label {
  text-transform: capitalize;
}
/*confimed screen*/

.mainWrapper__container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 1172px) {
    // margin-right: 1rem;
    // margin-left: 1rem;
    // padding-left: 5px;
    // padding-right: 5px;
  }
}

.responsive__store {
  &-locator {
    width: 100%;
    height: 100%;
  }
}
.mapboxgl-map {
  @media screen and (max-width: 659px) {
    max-height: 200px;
  }
}

.button-wrapper {
  @media screen and (max-width: 659px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.modal-footer > :not(:last-child) {
  @media screen and (max-width: 659px) {
    margin-right: 0.7rem;
  }
}
.margin__top {
  @media screen and (max-width: 659px) {
    margin-top: 1rem;
    margin-left: 1rem !important;
  }
}
.modal-content {
  @media screen and (max-width: 659px) {
    margin: 20px auto;
  }
}

@media screen and (max-width: 659px) {
  .mapboxgl-popup {
    transform: translate(-50%, 0px) translate(161px, 100px) !important;
  }
  .mapboxgl-popup-content {
    padding: 5px !important;
    p {
      font-size: 10px;
      line-height: 1.3;
      margin-bottom: 0;
    }
  }
}

.app-version {
  text-align: center;
  font-size: 12px;
  padding-bottom: 0.5rem;
  color: #8998a6;
}

.app-loader {
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customTooltip {
  box-shadow: 0 0 32px 0 rgba(51, 71, 91, 0.15);
  background-color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #4b5967;
}
.customArrow::before {
  border-top-color: #ffffff !important;
}

.no-wrap {
  white-space: nowrap;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-banner {
  height: 54px;
  width: 100%;
  margin: 10px 0;
  font-weight: bold;
  padding: 7px 25px 7px 30px;
  background-color: rgba(88, 157, 98, 0.1);
  border: 1px solid rgba(88, 157, 98, 0.1);
  display: flex;
  align-items: center;
  position: relative;
  // background-color: var(--theme__primary--color, $primaryColor);
  // border: solid 1px var(--theme__primary--color, $primaryColor);
  // justify-content: space-between;
  // background-image: linear-gradient(95deg, #5a5bff, #845aff);
  // font-size: 14px;

  &.position-icon-top-border {
    padding: 25px;
    // margin: 0 -1.25rem -1.25rem -1.25rem;

    svg {
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);

      height: 35px;
      width: 35px;
    }
  }

  &.position-icon-left-border {
    svg {
      position: absolute;
      left: -10px;
    }
  }

  &.light-theme-bg-color {
    background-color: var(--theme__primary--color-variant3, $primaryColor);
    border: solid 1px var(--theme__primary--color-variant3, $primaryColor);
  }

  &.dark-text {
    color: black;
  }

  &.dark-icon {
    svg {
      g {
        path {
          fill: black;
        }
      }
    }
  }

  &.light-text {
    color: white;
    h3 {
      color: white;
    }
  }

  &.light-icon {
    svg {
      g {
        path {
          fill: white;
        }
      }
    }
  }

  & > div {
    display: flex;
    align-items: center;
  }

  // h3 {
  // margin: 0;
  // font-size: 16px;
  font-weight: 600;
  // margin-left: 21px;
  color: #fff;
  // color: var(--theme__primary--color, $primaryColor);
  // }

  a {
    font-size: 12px;
    // color: #001135;
    font-weight: 500;
    line-height: 1.42;
    color: #fff;
    text-decoration: underline;
  }

  @media (max-width: 565px) {
    padding: 16px;

    h3 {
      // margin-left: 14px;
      font-size: 12px;
      // width: 180px;
    }
  }
}
