.refund-shipping {
  .container {
    margin-bottom: 16px;
  }
  .bottom-padding {
    margin-top: 55px;
  }
  .top-collapse {
    cursor: pointer;
  }
  .special-cursor {
    cursor: default;
    // .line-divider:nth-child(1) {
    //   margin-top: 15px;
    // }
  }
  .font-position {
    position: absolute;
  }
  .down_only {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-left: 0.5em;
  }

  .gutter-padding {
    padding: 0;
  }
  // .line-divider {
  //   height: 2px;
  //   border: dotted 1px #cbd6e2;
  //   margin-top: 10px;
  // }
  .textDiv {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5a5bff;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .button-style {
    text-align: center;
    padding-top: 64px;
    // padding-bottom: 72px;
    margin-bottom: 16px;
  }
  i {
    float: right;
    padding-top: 2px;
  }
}
