$negative_price_color: #ad2b2b;
$max-size: 565px;
@import 'common';
// @import "summary";
@import 'shipping-payment-methods';
@import 'shipping-rates';
@import 'address-block';
@import '../../mixins';
@import './qr-label-supported-locations';

.refund-shipping {
  &__container {
    max-width: 830px;
    margin: 55px auto 0;
  }
  .container {
    margin-bottom: 16px;
  }
  .top-collapse {
    cursor: pointer;
  }
  .special-cursor {
    cursor: default;
    .line-divider:nth-child(1) {
      margin-top: 15px;
    }
  }
  .font-position {
    position: absolute;
  }
  .down_only {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-left: 0.5em;
  }
  .amount-refund-text {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #001234;
  }
  .retref {
    left: 20px;
  }
  .total-price {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #5a5bff;
    padding-inline-start: 10px;
    float: right;
    &.negative {
      color: $negative_price_color;
    }
  }
  .product-review {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #516f90;
    padding-block-start: 9px;
    padding-block-end: 11px;
  }
  // .product-price {
  //   font-size: 14px;
  //   font-weight: normal;
  //   font-style: normal;
  //   font-stretch: normal;
  //   line-height: normal;
  //   letter-spacing: normal;
  //   text-align: right;
  //   color: #516f90;
  //   &.negative {
  //     color: $negative_price_color;
  //   }
  // }
  .method-type {
    padding: 3px 10px;
    &.return {
      border-radius: 4px;
      background-color: #fff6a3;
      font-size: 13px;
      line-height: 1.23;
      color: #79770b;
    }
    &.exchange {
      border-radius: 4px;
      background-color: #e9fbff;
      font-size: 13px;
      line-height: 1.23;
      color: #36acc6;
    }
  }
  .item-selected-text {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #001234;
  }
  .review-heading {
    height: 32px;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #001234;
    margin-bottom: 1em;
  }
  .refund-card {
    border-radius: 12px;
    border: solid 1px #eaf0f9;
    background-color: #ffffff;
    margin-top: 1rem;
  }

  .refund-body {
    //   padding-block-start: 16px;

    .refund_method {
      cursor: pointer;
      &.disabled-row {
        cursor: not-allowed !important;
      }
    }
    .option-icon {
      font-size: 18px;
    }
  }
  .refund-title {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #8c97b4;
    padding-bottom: 8px;
  }
  .gift-card {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  .refund-name {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #001234;
    padding-top: 4px;
  }
  .refund_detail-text {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7c98b6;
  }
  .gutter-padding {
    padding: 0;
  }
  .line-divider {
    height: 0px;
    border: dotted 1px #cbd6e2;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .refund-row {
    padding: 20px 0;
    &:hover {
      background-color: #fcfdfe;
    }
  }
  .address-block {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #001234;
    padding: 16px 0;
    margin-right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .base {
      max-width: 160px;
      @media (max-width: 767px) {
        margin-top: 1rem;
        margin-left: 15px;
      }
    }
    .adress-line-height {
      // line-height: 1.6;
    }
  }
  .base {
    border-radius: 3px;
    border: solid 1px rgba(90, 91, 255, 0.3);
    background-color: #ffffff;
    cursor: pointer;
    &.disable-button {
      opacity: 0.5;
      cursor: not-allowed;
    }
    @media screen and (max-width: $max-size) {
      max-width: 100px;
      width: 80px;
      margin-top: 1rem;
      margin-left: 15px;
    }
  }
  .textDiv {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5a5bff;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .button-style {
    text-align: center;
    padding-top: 64px;
    // padding-bottom: 72px;
    margin-bottom: 16px;
  }
  i {
    float: right;
    padding-top: 2px;
  }
  .method__padding {
    text-align: center;
  }
  .store-change {
    background-color: transparent;
    box-shadow: none;
    color: var(--theme__primary--color, $primaryColor);
    border: 0;
    cursor: pointer;
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    outline: none;
    &:focus {
      outline: none;
    }
  }

  @include continue-btn();

  .section-heading {
    // font-size: 18px;
    // font-weight: normal;
    // font-style: normal;
    // font-stretch: normal;
    // line-height: 1.33;
    // letter-spacing: normal;
    // color: #001234;
    padding-bottom: 1.12rem;

    &.small-col,
    &.small-col-right {
      // font-size: 18px;
      // line-height: 1.33;
      // color: #8c97b4;
      padding-bottom: 1.5rem;
    }
    // &.small-col-right {
    //   margin-left: 0.8rem;
    // }
  }

  .img-new-size {
    width: 100%;
    // height: 64px;
    height: 80px;
    object-fit: contain;
    object-position: top;
  }

  .product-row {
    // padding-top: 16px;
    // padding-left: 4em;
    &.refund {
      padding-left: 1.6em;
    }

    .product-name {
      // font-size: 14px;
      // line-height: 1.43;
      // color: #2a2f3b;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow-x: hidden;
      margin-bottom: 5px;
    }
    .product-price {
      // font-size: 14px;
      // line-height: 1.43;
      // color: #516f90;
      .vertical-divider-review {
        display: inline-block;
      }
    }
    .product-review {
      font-size: 13px;
      line-height: 1.43;
      color: #001234;
    }
  }

  @media screen and (max-width: 767px) {
    .product-row {
      .product-name {
        font-size: 13px;
      }
      .product-price {
        font-size: 13px;
      }
      .product-review {
        font-size: 13px;
      }
    }
    .content__checking {
      margin: 15px 0;

      transform: rotate(90deg);
    }
    .no-wrap {
      white-space: normal;
    }
  }
}
.loading-card {
  border-radius: 12px;
  border: solid 1px #eaf0f9;
  background-color: #ffffff;
  margin-top: 1rem;
}

.shipping-loading-card {
  border-radius: 12px;
  border: none;
  background-color: #ffffff;
  // margin-top: 1rem;
}

.OrderServiceDetails__terms {
  margin-top: 1rem;
  margin-bottom: 2rem;
  // color: #7c98b6;
  font-size: 11px;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-align: center;
}

.store-button {
  position: relative;
  margin: 0 0 1rem;
  padding-left: 90px;
  padding-right: calc(17.8px + 15px);

  @media (max-width: 991px) {
    padding-left: 90px;
  }
  @media (max-width: 460px) {
    padding-left: 70px;
    // text-align: center;
  }
}
.store-information {
  font-size: 14px;
  color: #516f90;

  .info-row {
    display: flex;
    align-items: flex-end;
  }

  p {
    margin: 0;
  }

  strong {
    color: #001234;
    font-weight: 500;
  }

  .store-change-wrapper {
    margin-left: 10px;
    &::before {
      content: '(';
    }
    &::after {
      content: ')';
    }

    button:hover {
      text-decoration: underline;
    }
  }
}

.return-item-row {
  & > div:not(:first-child) {
    display: none;
  }
}
