.qrLabelSupportedLocations {
  &__header {
    display: flex;
    align-items: center;
    padding: 0 0 16px;
    border-bottom: 1px dashed #eaf0f9;

    p {
      max-width: 312px;
      font-size: 14px;
      line-height: 1.4;
      color: #33475b;
      margin-right: 36px;
      margin-bottom: 0;
    }

    input[type='number'] {
      padding: 6px 12px;
      background-color: #ffffff;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      border: none;
      outline: none;
      font-size: 14px;
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }

      &::placeholder {
        color: #a6afb8;
      }
    }
    button {
      height: 33px;
      margin-left: 10px;
      min-width: unset;
      width: 54px;
      svg {
        transform: rotate(80deg) scale(0.9);
        transition: 0.2s;
        & > g {
          fill: #fff;
        }
      }

      &:not(:disabled):hover svg {
        transform: rotate(90deg) scale(1.1);
      }

      &:disabled {
        background-color: #cccccc;
        border-color: #cccccc;
        opacity: 1;
      }
    }
  }

  &__inputContainer {
    display: flex;
    align-items: center;
  }

  @media (max-width: $max-size) {
    &__header {
      flex-direction: column;
      align-items: flex-start;

      p {
        max-width: unset;
        margin: 0;
      }
    }
    &__inputContainer {
      margin-top: 12px;
      width: 100%;
      input {
        flex: 1;
      }
    }
  }
}
