@import url('https://rsms.me/inter/inter-ui.css');
@font-face {
  font-family: 'Calibre';
  src: url('./fonts/calibre//Calibre-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('./fonts/calibre//Calibre-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('./fonts/calibre//Calibre-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('./fonts/calibre//Calibre-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Calibre';
  src: url('./fonts/calibre//Calibre-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Calibre';
  src: url('./fonts/calibre//Calibre-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('./fonts/calibre//Calibre-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('./fonts/calibre//Calibre-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('./fonts/calibre//Calibre-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
