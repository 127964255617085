$text-primary-color: #0c131a;
$text-secondary-color: rgba(11, 18, 24, 0.7);
$text-tertiary-color: rgba(11, 18, 24, 0.5);

@mixin primary-color {
  color: $text-primary-color;
}

@mixin secondary-color {
  color: $text-secondary-color;
}

@mixin font-weight-bold {
  font-weight: bold;
}

@mixin font-weight-normal {
  font-weight: normal;
}

@mixin font-size-16 {
  font-size: 16px;
}
@mixin font-size-14 {
  font-size: 14px;
}
@mixin font-size-12 {
  font-size: 12px;
}
@mixin font-size-10 {
  font-size: 10px;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  @include primary-color;
}

h2.light,
h3.light,
h4.light {
  @include secondary-color;
}

h1.inline,
h2.inline,
h3.inline,
h4.inline {
  display: inline;
}

h1.color-inherit,
h2.color-inherit,
h3.color-inherit,
h4.color-inherit {
  color: inherit;
}

h1.font-weight-medium,
h2.font-weight-medium,
h3.font-weight-medium,
h4.font-weight-medium {
  font-weight: 600;
}

h1 {
  font-size: 24px;
  @include primary-color;
}

h2 {
  @include font-size-16;
}

h3 {
  @include font-size-14;
}

h4 {
  @include font-size-12;
}

p {
  @include secondary-color;
  @include font-size-14;
  margin: 0;
  line-height: 19px;
}

p.size-12 {
  @include font-size-12;
}

p.size-10 {
  @include font-size-10;
}

p.dark {
  @include primary-color;
}
