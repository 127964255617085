.upload-photos {
  .photos-wrapper {
    display: flex;

    .mobile-card {
      flex: 1;
    }

    .mobile-card ~ .mobile-card {
      margin-left: 20px;
    }
  }

  .upload-photos-title {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-size: 18px;
      font-weight: 600;
      color: #001234;
      line-height: 1.78;
      margin: 0;
    }
    span {
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
      line-height: 1.78;
      text-decoration: underline;
      color: var(--theme__primary--color, $primaryColor);
    }
  }
  .thumbnail.card {
    height: 210px;
    border-radius: var(--theme__primary-btn--border-radius, 0.25rem);
    border: 2px dashed;
    // background-color: #fcfdfe;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    // color: #7c98b6;

    @include primary-border-color-only-var3;
    @include primary-bg-color-var4;

    &:hover {
      cursor: pointer;
    }
    &.bg-image {
      background-size: cover;
      background-position: center;
      border: none;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
    .icon-row {
      height: 100%;

      .uplaod-progress-track {
        border-radius: var(--theme__primary-btn--border-radius, 0.25rem);
      }

      .upload-progress-bar {
        background-color: var(--theme__primary--color, $primaryColor);
      }

      .add-icon,
      .cross-icon {
        position: absolute;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 8px 0 rgba(51, 71, 91, 0.1);
        background-color: #ffffff;
        border-radius: 50%;
        left: 50%;
        bottom: -21px;
        transform: translateX(-50%);
        padding: 13px;

        &:hover i {
          opacity: 0.7;
        }

        i {
          font-size: 16px;
          // color: var(--theme__primary--color, $primaryColor);
        }
      }
      .cross-icon i {
        transform: rotate(45deg);
      }
    }
    .progress {
      width: 90%;
      margin: auto;
    }
  }
  p.message {
    width: auto;
    height: 60px;
    margin: 70px 0px 120px 0px;
    // font-size: 13px;
    // line-height: 1.54;
    text-align: center;
    // color: #516f90;
  }
  .drag-drop-message {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #33475b;
    position: relative;
    top: 3.5rem;
    .file-text {
      font-weight: 500;
      color: #5a5bff;
      cursor: pointer;
    }
  }

  @media screen and (max-width: $break-small) {
    .photos-wrapper {
      display: block;

      .mobile-card ~ .mobile-card {
        margin: 0;
      }

      .mobile-card:first-of-type {
        margin-bottom: 35px;
      }

      .mobile-card:last-of-type {
        margin-left: 20px;
      }

      .mobile-card:not(:first-of-type) {
        display: inline-flex;
        width: calc((100% - 20px) / 2);

        & > div {
          width: 100%;
        }
      }
    }
  }
}
