.trigger__notification {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-top: 1rem;
  // margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  span {
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
    :hover {
      color: #5a5bff;
    }
  }
  &.regular {
    color: rgba(255, 126, 78, 0.7) !important;
    padding: 0.75rem 0px;
    margin: 0px;
  }
  &.filled-red {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}
