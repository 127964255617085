.exchange-incentives-modal {
  $x-gap: 64px;
  $y-gap: 36px;
  max-width: 778px;
  // max-width: unset;

  &__content {
    padding: 36px 44px;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 16px 0 rgba(90, 91, 255, 0.13);
  }

  &__header {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  }

  &__title {
    margin: 0;
    // font-size: 36px;
    // color: #000113;
    // font-weight: bold;
    // line-height: 1.17;
    // padding-right: 64px;
  }

  &__description {
    // font-size: 14px;
    // color: #6e7c8a;
    // line-height: 1.36;
    margin-bottom: 36px;
    // padding-right: 72px;
  }

  &__features-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__feature {
    display: flex;
    align-items: flex-start;
    width: calc((100% - #{$x-gap}) / 2);
    margin-bottom: $y-gap;

    &:nth-child(2n) {
      margin-left: $x-gap;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--icon {
      min-width: 42px;
      max-width: 42px;
      height: 42px;
      display: flex;
      margin-right: 18px;
      // border-radius: 8px;
      align-items: center;
      justify-content: center;
      // background-color: #fff;
      // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);

      @include primary-icon-color-var1;

      svg {
        &.discount-forwarding {
          transform: scale(0.8);
        }
      }
    }

    &--info {
      h5 {
        font-size: 16px;
        color: #000113;
        font-weight: bold;
      }

      p {
        font-size: 12px;
        line-height: 1.42;
        color: #6e7c8a;
        margin-bottom: 15px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    $x-gap: 48px;
    $y-gap: 28px;
    max-width: unset;
    padding: 0 20px;

    &__feature {
      width: calc((100% - #{$x-gap}) / 2);

      &:nth-child(2n) {
        margin-left: $x-gap;
        margin-bottom: $y-gap;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 700px) {
    padding: 0 12px;

    &__title {
      font-size: 28px;
    }

    &__feature {
      width: 100%;
      margin-left: 0 !important;
      margin-bottom: 8px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  @media screen and (max-width: $break-small) {
    padding: 0;

    &__content {
      padding: 28px 32px;
    }

    &__title {
      font-size: 20px;
    }

    &__description {
      font-size: 12px;
      padding-right: 32px;
    }

    &__feature {
      position: relative;
      z-index: 1;

      &--icon {
        min-width: 32px;
        max-width: 32px;
        height: 32px;

        svg {
          transform: scale(0.9);

          &.discount-forwarding {
            transform: scale(0.78);
          }
        }
      }

      &--info {
        h5 {
          font-size: 15px;
        }
      }
    }
  }
}
