.container {
  padding: 16px;
  font-size: 14px;
  text-align: center;
  position: fixed;
  top: 71px;
  left: 0;
  width: 100%;
  z-index: 1000;
  color: #ffffff;
  background-color: #da4d4d;
  // background-color: var(--theme__primary--color, #444);
  transform-origin: top;
  opacity: 0;
  transform: scaleY(0.5);
  animation: unfold 0.6s 0.3s forwards;

  h4 {
    margin-bottom: 10px;
    color: inherit;
    font-size: 1.42em;
    font-weight: 800;
    line-height: 1.21;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translateY(10px);
    animation: appear 0.6s 0.8s forwards;
  }

  p {
    color: inherit;
    font-size: 1em;
    line-height: 1.36;
    margin-bottom: 10px;
    opacity: 0;
    transform: translateY(10px);
    animation: appear 0.6s 1s forwards;
  }

  .contact {
    margin: 0;
    opacity: 0;
    transform: translateY(10px);
    animation: appear 0.6s 1.2s forwards;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
}

@keyframes unfold {
  from {
    opacity: 0;
    transform: scaleY(0.5);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
