@import '../../mixins';

.review-summary {
  @include continue-btn();

  .card-style {
    // border-radius: 6px;
    // border: solid 1px #ecf1f4;
    // background-color: #ffffff;
    // margin-bottom: 2em;

    @include primary-border-color-only-var3;
    @include br-4;
  }
  .upper-margin {
    margin-top: 55px;
  }
  .section-heading {
    // font-size: 18px;
    // font-weight: normal;
    // font-style: normal;
    // font-stretch: normal;
    // line-height: 1.33;
    // letter-spacing: normal;
    // color: #001234;
    padding-bottom: 1.12rem;

    &.small-col,
    &.small-col-right {
      // font-size: 18px;
      // line-height: 1.33;
      // color: #8c97b4;
      padding-bottom: 1.5rem;
    }
    // &.small-col-right {
    //   margin-left: 0.8rem;
    // }
  }
  .img-new-size {
    width: 64px;
    height: 64px;
    object-fit: contain;
    object-position: top;
  }
  .product-row {
    // padding-top: 16px;
    // padding-left: 4em;
    &.refund {
      padding-left: 1.6em;
    }

    .product-name {
      // font-size: 14px;
      // line-height: 1.43;
      // color: #2a2f3b;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow-x: hidden;
      margin-bottom: 5px;
    }
    .product-price {
      // font-size: 14px;
      // line-height: 1.43;
      // color: #516f90;
      .vertical-divider-review {
        display: inline-block;
      }
    }
    .product-review {
      font-size: 13px;
      line-height: 1.43;
      color: #001234;
    }
  }

  .line-divider {
    height: 2px;
    border-top: 1px dotted #cbd6e2;
    margin-bottom: 1.667em;
    margin-top: 1.667em;
    &.active {
      margin-bottom: 1em;
      margin-top: 1em;
    }
    &.small-col {
      margin-bottom: 1em;
      margin-top: 1em;
    }
    &.between-exchanges {
      margin: 1.5rem 0;
      opacity: 1;
    }
  }
  .sub-total-text {
    // font-size: 13px;
    // font-weight: 500;
    // font-style: normal;
    // font-stretch: normal;
    // line-height: 1.85;
    // letter-spacing: 0.9px;
    // color: #8c97b4;

    &.final {
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.85;
      letter-spacing: 0.9px;
      // color: #5a5bff;
      color: #0012348c;
    }
  }

  .tag {
    display: flex;
    // align-items: center;
    justify-content: flex-end;

    .credit-amount {
      padding: 4px 10px;
      // background-color: #a4e1f1;
      // color: #000;
      font-size: 10px;
      font-weight: 500;

      // @include primary-bg-color-var1;
    }
  }

  .waiver-description {
    margin-top: 5px;
    // font-size: 12px;
    // color: var(--theme__primary--color, $primaryColor);
    // border-radius: 5px;
    // letter-spacing: 0;
    // font-weight: normal;
  }

  .sub-total-price {
    // font-size: 18px;
    // font-weight: normal;
    // font-style: normal;
    // font-stretch: normal;
    // line-height: 1.33;
    // letter-spacing: normal;
    text-align: right;
    // color: #001234;
    &.final-price {
      // font-weight: bold;
      // line-height: 1.33;
      // text-align: right;
      color: var(--theme__primary--color, $primaryColor);

      .finalPrice__presentment {
        margin-top: 4px;

        &--currency {
          margin-right: 8px;
          font-size: 12px;
          font-weight: 400;
          opacity: 0.7;
        }

        &--amount {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .waiver {
      display: none;
    }

    &.waive-off {
      .amount {
        font-size: 0.8em;
        opacity: 0.8;
        text-decoration: line-through;
      }

      .waiver {
        display: inline;
        margin-left: 10px;
        white-space: nowrap;
      }
    }
  }
  .refund-text-position {
    left: -20px;
    .title {
      // font-size: 14px;
      // font-weight: normal;
      // font-style: normal;
      // font-stretch: normal;
      // line-height: 1.43;
      // letter-spacing: normal;
      // color: #001234;
    }
    .subtitle {
      // font-size: 13px;
      // font-weight: normal;
      // font-style: normal;
      // font-stretch: normal;
      // line-height: normal;
      // letter-spacing: normal;
      // color: #7c98b6;
    }
  }
  .gift-card {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  .info-card {
    &:after {
      width: 1px;
      height: 212px;
      background-color: #dfe3eb;
    }
  }
  .vertical-divider {
    width: 1px;
    height: 212px;
    background-color: #dfe3eb;
    position: absolute;
    left: 50%;
  }
  .right-col {
    margin-left: 0.3rem;
    .address-info {
      // font-size: 14px;
      // line-height: 1.6;
      // color: #001234;
    }
    .info {
      // font-size: 12px;
      // line-height: 1.5;
      // color: #516f90;
      margin-top: 1.5rem;
      > span {
        font-weight: 500;
        color: #001234;
        a {
          color: #001234;
        }
      }
    }
  }
  .div-alignment {
    text-align: center;
    .button-alignment {
      margin-top: 2em;
    }
  }
  .divider-line {
    height: 2px;
    border: dotted 1px #cbd6e2;
    position: relative;
    width: 100%;
    margin: 1em;
  }
  // .content__checking {
  //   &:before {
  //     content: '\f054';
  //     font-family: FontAwesome;
  //     width: 24px;
  //     height: 24px;
  //     font-size: 12px;
  //     color: #ffffff;
  //     background-color: var(--theme__primary--color, $primaryColor);
  //     border-radius: 50%;
  //     position: absolute;
  //     top: 31%;
  //     left: -3%;
  //     text-align: center;
  //     padding-top: 3px;
  //     padding-left: 2px;
  //   }
  // }

  .light-border-left {
    border-left: 1px dotted #cbd6e2;
  }

  .light-border-top {
    border-top: none;
  }

  @media screen and (max-width: 767px) {
    .product-row {
      .product-name {
        font-size: 13px;
      }
      .product-price {
        font-size: 13px;
      }
      .product-review {
        font-size: 13px;
      }
    }
    .content__checking {
      margin: 15px 0;

      transform: rotate(90deg);
      // &::before {
      //   content: '\f078';
      //   font-family: FontAwesome;
      //   font-style: normal;
      //   font-weight: normal;
      //   text-decoration: inherit;
      //   /*--adjust as necessary--*/
      //   top: -28px;
      //   left: 0;
      //   right: 0;
      //   padding-top: 3px;
      //   padding-right: 2px;
      //   margin: auto;
      // }
    }
    .sub-total-text {
      font-size: 11px;
      h2 {
        font-size: 1.1em;
      }

      &.final {
        font-size: 11px;
      }
    }
    .sub-total-price {
      // font-size: 16px;

      &.final-price {
        // font-size: 16px;
      }
    }
    .refund-text-position {
      left: 0px;
    }
    .vertical-divider {
      width: 100%;
      height: 1px;
      background-color: #dfe3eb;
      position: relative;
      top: 50%;
      left: 0;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .light-border-left {
      border: none;
    }

    .light-border-top {
      border-top: 1px dotted #cbd6e2;
      padding-top: 10px;
      margin-top: 10px;
    }
  }

  .exchange-item-alert {
    // p {
    //   font-size: 12px;
    // }
    a {
      color: #5a5bff;
    }
  }
}
