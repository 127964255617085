.actions {
  margin: 10px 0 30px;
  max-width: 100%;

  & > div {
    &:last-of-type {
      text-align: right;
    }
  }

  button {
    font-size: 18px;
    width: calc(100% - 5px);
    height: 48px;

    & * {
      pointer-events: none;
    }

    i {
      position: absolute;
    }
  }

  button.back {
    i {
      left: 15px;
    }
  }

  button.continue {
    i {
      right: 15px;
    }
  }

  .set-width {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.WrongColorExchange {
  margin-top: 15px;

  .actions {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .actions {
    button {
      margin-top: 10px;
      max-width: 100%;
      width: 100%;
    }
  }
}
