.shippingLabelType,
.qrVsLabelModal {
  $checkbox-width: 32px;

  max-width: 820px;
  &__content {
    border: none;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 8px 0 rgba(90, 91, 255, 0.15);
  }

  &__title,
  &__container,
  &__footer {
    margin: 0;
    padding: 22px 0 0;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 18px;
      color: #a6afb8;
      margin: 0;
      font-weight: 500;
      margin-right: 50px;
    }

    svg {
      cursor: pointer;
    }
  }

  &__gridrow {
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
    position: relative;

    @media (max-width: 660px) {
      flex-direction: column;
    }
  }

  &__col {
    flex: 1;
    &:first-child {
      padding: 0 20px 0 0;
    }
    &:last-child {
      padding: 0 0 0 20px;
    }

    @media (max-width: 660px) {
      &:first-child {
        padding: 0 0 15px !important;
      }
      &:last-child {
        padding: 15px 0 0 !important;
      }
    }
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &.selected {
      cursor: default;
    }
  }

  &__optionCheck {
    width: $checkbox-width;
  }

  &__optionIcon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  &__optionTitle {
    margin: 0;
    // font-size: 16px;
    // font-weight: 400;
    // color: #001135;

    &.selected {
      // font-weight: 500;
      font-weight: bold !important;
    }
  }

  &__optionDescription {
    // font-size: 10px;
    // font-weight: 300;
    margin: 12px 0 0;
    // color: #001a44;
    // line-height: 1.4;
    padding-left: $checkbox-width;

    a {
      color: var(--theme__primary--color, $primaryColor);
      text-decoration: underline;
    }
  }

  &__qrLocations {
    padding: 24px 20px;
    // background-color: #f4f8ff;
    @include primary-bg-color-var4;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      padding: 12px;
    }
  }

  &__notApplicableTitle {
    margin-bottom: 4px;
    padding: 0 22px;
    font-size: 14px;
    font-weight: 500;
    color: #001135;
  }

  &__notApplicableMessage {
    padding: 0 22px;
    font-size: 12px;
    font-weight: 500;
    color: #7d97b8;
  }

  @media (max-width: $max-size) {
    &__qrLocations {
      // margin: 0 -16px;
      margin: 0 -1.25rem -1.25rem -1.25rem;
    }
  }
}
