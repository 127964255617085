.container {
  color: var(--theme__header-link--color, #73818f);
  font-size: var(--theme__header-link--font-size, 16px);
  font-weight: var(--theme__header-link--font-weight, 400);
  font-family: var(
    --theme__header-link--font-family,
    var(--theme__font-family, 'Inter UI', sans-serif)
  );

  @media (max-width: 575px) {
    padding-right: 0;
  }
}
