$green: #7bba84;
$black: #0c131a;

@mixin selection {
  display: inline-flex;
  align-items: center;
  $box-dimension: 14px;
  $check-left: $box-dimension * 1/3;
  $check-right: $box-dimension * 2/3;

  input {
    display: none;

    & + label {
      cursor: pointer;
      margin: 0;
      display: inline-flex;
      width: $box-dimension;
      height: $box-dimension;
      border-radius: 1px;
      border: solid 1px rgba(12, 19, 26, 0.5);
      background-color: transparent;
      position: relative;
      justify-content: center;
      align-items: center;
    }

    &:checked + label {
      border-color: $black;
      background-color: $black;
      &::before,
      &::after {
        display: initial;
      }
    }

    &:hover + label {
      border-color: $black;
      box-shadow: 0 0 4px 0 rgba(12, 19, 26, 0.25);
    }

    &:disabled:hover + label {
      box-shadow: none;
    }

    &:disabled:checked + label {
      cursor: not-allowed;
      border-color: #ddd;
      background-color: #ddd;
    }

    &:disabled:not(:checked) + label {
      cursor: not-allowed;
      border-color: #ddd;
      background-color: #ddd;
    }

    &:disabled + label + label.selection-title {
      cursor: not-allowed;
    }
  }

  .selection-title {
    cursor: pointer;
    margin: 0;
    padding: 0 0 0 15px;
    font-size: 14px;
    color: $black;
  }
}
