.BrowseProductVariants {
  margin-top: 15px;

  & .actions {
    margin-bottom: 0px;
  }

  &__footer {
    // margin-top: 16px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 1.38;
    color: #7c98b6;
    padding: 10px 0px;
    display: flex;

    i {
      border: 1px solid #7c98b6;
      border-radius: 50%;
      display: inline-block;
      padding: 3px 3px 3px 4px;
      width: 18px;
      height: 17px;
      text-align: center;
      font-size: 8px;
    }

    &-message {
      margin-left: 8px;
      strong {
        color: #445d79;
        font-weight: 600;
      }
    }
  }

  .ProductVariant {
    &__image {
      border-radius: 3px;
      background-size: contain;
      background-position: top center;
      background-repeat: no-repeat;
      width: 100%;
      height: 200px;
    }
    &__title {
      // strong {
      // font-weight: normal;
      font-size: 14px !important;
      // line-height: 1.4;
      // color: #000;
      // }
    }
    &__price-wrapper {
      display: inline-flex;
      align-items: center;
    }
    &__quantity-indicator {
      font-size: 14px;
      margin-left: 8px;
      line-height: 0;
      color: #888;
    }
    &__price {
      // font-size: 20px;
      // font-weight: bold;
      // line-height: 1.2;
      // color: #001234;
    }

    &__price-container {
      margin: 8px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__priceDiff {
      &--content {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.71;
        color: #0c1a28;
        vertical-align: top;
        position: relative;
        display: inline-flex;
        align-items: center;
      }

      & .arrow {
        font-size: 25px;
        position: absolute;
        top: 6px;
        left: -20px;
        &.fa-sort-up,
        &.up {
          color: red;
        }
        &.fa-sort-down,
        &.down {
          color: #23ca47;
          top: auto;
          bottom: 4px;
        }
      }

      &--infoIcon {
        margin-left: 10px;
        width: 16px;
        height: 16px;
      }

      &__item-indicator {
        margin-left: 4px;
        font-size: 12px;
        color: #888;
      }

      &_alert {
        font-size: 12px;
        color: #001234;
        padding: 10px;
      }
    }
    &__subText {
      font-size: 12px;
      line-height: 1.5;
      color: #516f90;
    }
    .attribute-label {
      // font-size: 12px;
      font-size: 10px;
      font-weight: 500;
      padding-bottom: 6px;
      letter-spacing: 1px;
      color: #001234;
      & .selected {
        font-weight: 600;
        text-transform: capitalize;
      }
    }
    &__colorVariants {
      &_tile {
        width: 64px;
        border-radius: 3px;
        cursor: pointer;
        margin-right: 6px;
        border: 1px solid #dfe3eb;
        display: inline-block;
        opacity: 0.7;
        background-size: cover !important;
        width: 48px;
        height: 48px;
        &.selected {
          opacity: 1;
          border: 2px solid var(--theme__primary--color, $primaryColor);
        }
      }
    }

    .attribute-option {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: #ffffff;
      cursor: pointer;
      text-transform: uppercase;
      padding: 7px 20px;
      font-size: 13px;
      border: solid 1px #dfe3eb;
      border-radius: 5px;
      &.active {
        background-color: #001234;
        background-color: var(--theme__primary--color);
        color: white;
      }
      &:focus {
        outline: none;
      }
      &.disabled {
        cursor: not-allowed;
        background: red;
      }
    }

    .attribute-value__select {
      display: inline-flex;
      align-items: center;
      position: relative;
      $indicator-width: 18px;
      $indicator-offset: 8px;

      &.default {
        .attribute-value__select__input {
          // color: rgb(140, 140, 140);
          // border-color: rgb(140, 140, 140);
          // border-color: rgba(127, 154, 184, 0.19);
          @include select-box-primary-border-color;
        }
        .attribute-value__select__indicator {
          path:last-of-type {
            stroke: rgb(140, 140, 140);
          }
        }
      }

      &__input {
        font-size: 15px;
        flex-grow: 1;
        // color: var(--theme__primary--color, rgb(70, 70, 70));
        // border-width: 1px;
        // border-style: solid;
        // border-color: var(--theme__primary--color, rgb(70, 70, 70));
        // border-color: rgba(127, 154, 184, 0.19);
        // border-radius: 3px;
        @include select-box-primary-border-color;
        min-width: 100px;
        appearance: none;
        padding: 3px 10px;
        padding-right: $indicator-offset * 2 + $indicator-width;
        background-color: #fff;
        outline: 0;
      }
      &__indicator {
        position: absolute;
        right: $indicator-offset;
        width: $indicator-width;
        height: $indicator-width;
        pointer-events: none;

        path:last-of-type {
          // stroke: var(--theme__primary--color, rgb(70, 70, 70));
          stroke: #0c131a;
        }
      }
    }

    @media screen and (max-width: 768px) {
      &__image {
        height: 300px;
        // height: 100%;
        // background-position: top left;
        margin-bottom: 20px;
      }
      &__colorVariants {
        &_tile {
          height: 35px;
          width: 35px;
        }
      }

      // .col{
      //   padding-left: 0;
      //   // padding-right: 5px;
      // }

      .attribute-value__select {
        width: 100%;
        &__input {
          // min-width: auto;
          font-size: 12px;
        }
      }

      .smaller-width {
        width: calc(100% - 74px);
      }
    }

    @media screen and (max-width: $break-small) {
      &__image {
        // height: 300px;
        // height: 100%;
        background-position: top center;
        // margin: 0 -15px;
        margin-bottom: 10px;
        border-radius: 0;
      }

      &__title {
        font-size: 11px;
        line-height: 1.33;
        font-weight: 400;
      }

      // .attribute-value__select {
      //   width: 100%;
      // }
    }
  }

  .no-variants-found {
    padding: 10px;
    background-color: rgb(255, 251, 218);
    // background: linear-gradient(
    //   to right,
    //   rgb(255, 248, 186),
    //   rgb(255, 251, 218),
    //   rgb(255, 248, 186)
    // );
    border-radius: 6px;
    color: rgb(146, 132, 0);
    font-size: 14px;
  }
}

.BrowseProductVariants__loaderSkeleton {
  // height: 300px;
  display: flex;
  gap: 30px;

  @keyframes fetching {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  @mixin loading-element($delay: 0s) {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 100%;
      transform: translateX(-50%);
      background: linear-gradient(
        90deg,
        rgb(243, 243, 243) 0%,
        rgb(223, 223, 223) 50%,
        rgb(243, 243, 243) 100%
      );
      animation: fetching 1s $delay alternate-reverse infinite;
    }
  }

  * {
    border-radius: 4px;
    padding: 0;
    margin: 0;
  }

  .image,
  .content {
    flex: 1;
  }

  .image {
    @include loading-element;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .title,
  .price {
    height: 16px;
    @include loading-element(0.1s);
  }

  .price {
    width: 80px;
    height: 24px;
  }

  .colors {
    margin-bottom: 10px;

    &-header {
      height: 12px;
      width: 100px;
      margin-bottom: 10px;
      // @include loading-element;
    }

    &-list {
      list-style: none;
      display: flex;
      gap: 6px;

      &-item {
        width: 50px;
        height: 50px;
        @include loading-element(0.2s);
      }
    }
  }

  .size {
    &-header {
      height: 12px;
      width: 50px;
      margin-bottom: 10px;
      @include loading-element(0.3s);
    }

    &-select {
      height: 30px;
      width: 140px;
      @include loading-element(0.3s);
    }
  }
}
