.exchange-incentives-banner {
  display: flex;
  align-items: center;
  padding: 18px 20px;
  // max-width: 710px;
  margin: 40px auto 0;
  opacity: 0;
  transform: translateY(10px) scale(0.8);
  animation: show-banner 0.6s 0.3s forwards;

  &__icon {
    width: 56px;
    min-width: 44px;
    height: 44px;
    padding: 4px;
    // border-radius: 6px;
    // background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: 0 0 4px 0 rgba(90, 91, 255, 0.17);
    // opacity: 0;
    // transform: translateY(10px);
    animation: show-element 0.2s 0.6s forwards;

    svg {
      @include primary-icon-color-var1;
      g {
        fill: inherit;
        path {
          fill: inherit;
        }
      }
    }
  }

  &__content {
    padding: 0 18px;
    width: 100%;

    h5 {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 6px;
      opacity: 0;
      transform: translateY(10px);
      animation: show-element 0.2s 0.75s forwards;
    }

    p {
      color: #000;
      margin: 0;
      font-size: 12px;
      line-height: 1.4;
      opacity: 0;
      transform: translateY(10px);
      animation: show-element 0.2s 0.85s forwards;
    }
  }

  &__action button {
    white-space: nowrap;
    font-size: 13px;
    padding: 5px 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0;
    transform: translateY(10px);
    animation: show-element 0.2s 1s forwards;
    color: inherit;

    &:active,
    &:focus {
      outline-color: var(--theme__primary--color);
    }
  }

  @media screen and (max-width: 700px) {
    &__icon {
      display: none;
    }
    &__content {
      padding: 0 36px 0 0;
    }
  }

  @media screen and (max-width: $break-small) {
    flex-direction: column;
    &__content {
      padding: 0;
    }
    &__action {
      width: 100%;
      margin-top: 16px;
    }
  }
}

@keyframes show-banner {
  from {
    opacity: 0;
    transform: translateY(10px) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes show-element {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
