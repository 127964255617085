@import '../../variables';

$size-small: 659px;
.address-border {
  .form-control:not([disabled]) {
    background-color: transparent;
    border-radius: 0;
    border: none;
    box-shadow: inset 0 -1px 0 0 #dfe3eb;

    &:focus {
      background-color: #ffffff;
      box-shadow: inset 0 -2px 0 0 var(--theme__primary--color, $primaryColor);
    }

    &.is-invalid {
      box-shadow: inset 0 -1px 0 0 #dc3545;
    }
  }
  .v-col {
    display: flex;
    flex-direction: column;

    & > label {
      margin: 4px 0;
    }
  }
  .editModal__body-customComponent.is-invalid {
    .react-select-container .react-select__control {
      border-color: #dc3545;
    }
    & + .invalid-feedback {
      display: block;
    }
  }
  .label-form,
  .editModal__body-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    color: #7c98b6;
    margin: 0;
  }
  .disabled-text {
    font-size: 16px;
    color: #516f90;
    cursor: not-allowed;
  }
  .actionButtonsPadding {
    padding-top: 2rem;
  }
  .cancel-button,
  .save-button {
    width: 100%;
    height: 48px;
    font-size: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
  .save-button {
    @media (max-width: 768px) {
      margin-top: 10px;
    }
  }

  .modal-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;

    &-text {
      font-size: 22px;
      line-height: 1.2;
      font-weight: 500;
      color: #000113;
      @media (max-width: 560px) {
        font-size: 20px;
      }
      @media (max-width: 480px) {
        font-size: 18px;
      }
      // @media (max-width: 420px) {
      //   font-size: 16px;
      // }
    }
    &-img {
      cursor: pointer;
    }
  }
  .line-margin {
    margin-left: 15px;
    margin-right: 15px;
  }
  .address-helpText {
    width: 85%;
    margin-top: 0.5rem;
    font-size: 14px;
    color: var(--theme__primary--color, #444);
  }
}
.modal-dimension {
  margin-top: 50px;
  margin-bottom: 0px;
  // min-height: 100vh;
  width: 650px;
  max-width: 90%;
  &.set-width {
    width: 500px;
  }
  @media screen and (max-width: $size-small) {
    max-width: 96%;
  }
  .modal-content {
    border-radius: 12px;
    box-shadow: 0 24px 40px 0 rgba(81, 111, 144, 0.1);
    background-color: #ffffff;
    border-color: white;
    padding: 24px 24px;
  }
  .modal-body {
    padding: 10px 0px 0px 0px;
  }
  .form-control {
    height: calc(1.5em + 0.75rem + 2px);
  }
}
.select-focus {
  &:after {
    content: '<>';
  }
  &:focus {
    outline: none;
    appearance: none;
  }
}

.static-backdrop {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  pointer-events: none;
}
