.custom-popup-modal {
  max-width: 778px !important;
  margin: 60px auto !important;

  &__content {
    border: none;
    border-radius: 8px;
    background-color: #fff;

    box-shadow: 0 0 16px 0 rgba(90, 91, 255, 0.13);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__header {
    display: flex;
    padding: 36px 44px 16px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  }

  &__title {
    margin: 0;
    // font-size: 36px;
    // color: #000113;
    // font-weight: bold;
    // line-height: 1.17;
    // padding-right: 64px;
  }

  &__body {
    padding: 0 44px 36px;
    min-height: 300px;
    max-height: calc(100vh - 114px - 146px - 120px);
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    padding: 28px 44px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.14);

    &--confirmation {
      margin-bottom: 28px;
    }

    &--message {
      font-size: 14px;
      font-weight: 500;
      color: #0c131a;
    }

    &--actions {
      display: flex;
      justify-content: flex-end;
    }

    &--continueBtn:is(button) {
      padding: 14px;
      font-size: 18px;
      min-width: 200px;
    }
  }
}
