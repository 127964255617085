@import '../../mixins';

.storeLocatorModal {
  max-width: 650px;
  margin: 0px auto;

  @include date-picker();

  .modal-content {
    box-shadow: 0 24px 40px 0 rgba(81, 111, 144, 0.1);
    border: 0;
    border-radius: 0px;
  }

  .modal-body {
    padding: 1.5rem;
    padding-bottom: 120px;
  }

  .close-button {
    cursor: pointer;
  }

  .mapboxgl-popup-content {
    width: 200px;

    @media screen and (max-width: 659px) {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.6rem;

    .row {
      align-items: center;
    }
  }

  // &__title {
  // color: #001234;
  // font-size: 1rem;
  // line-height: 1.25;
  // margin-bottom: 0;
  // font-weight: 500;
  // }

  &__description {
    padding-right: 120px;
    // color: #001234;
    // font-size: 0.8rem;
    margin-bottom: 1.25rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px dashed #cbd6e2;
  }

  &__helpText {
    color: #516f90;
    font-size: 0.75rem;
    line-height: 1.33;
    margin-bottom: 0;
  }

  &__dateWrapper {
    position: relative;
  }
  &__dateWrapper--noDate .react-datepicker__input-container {
    position: relative;
  }
  &__dateWrapper--noDate .react-datepicker__input-container::after {
    position: absolute;
    content: 'mm/dd/yyyy';
    left: 1.25rem;
    top: 0.65rem;
    font-size: 14px;
    color: #7c98b6;
    pointer-events: none;
  }
  &__dateInput {
    max-width: 100%;
    position: relative;
    width: 100%;
    min-width: 150px;
    border: 1px solid #eaf0f9;
    padding: 0.75rem 1.25rem;
    font-size: 14px;
    color: #001234;
    line-height: 1.15;
    padding-right: 3rem;
    border-radius: 4px;
    pointer-events: auto;
    &::placeholder {
      color: #7c98b6;
    }
    &:focus {
      outline: none;
      border-color: var(--theme__primary--color, $primaryColor);
    }
  }

  &__body {
    overflow: auto;
  }

  &__body &__title {
    margin-bottom: 1.5rem;
  }

  &__map {
    height: 280px;
    width: 100%;
    margin-bottom: 1.25rem;
  }

  &__button {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;

    button {
      height: 50px;
      width: 70%;
      position: relative;

      span {
        font-size: 16px;
      }

      i {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &__popup {
    h6 {
      font-size: 14px;
      color: #001234;
      padding-right: 0.5rem;
      line-height: 1.33;
    }
    p {
      margin-bottom: 0;
      font-size: 12px;
      color: #516f90;
      line-height: 1.43;
    }
  }
  .StoreCard {
    padding: 1rem;
    border-bottom: 1px dashed #cbd6e2;
    cursor: pointer;
    &.single-item-background {
      background-color: #f5f8fb;
      &:hover {
        background-color: #f6f7f9;
      }
    }

    &:hover {
      background-color: #fcfdfe;
    }

    &:last-child {
      border-bottom: 0;
    }

    &__content {
      display: flex;
      align-items: center;
    }

    &__number {
      border: 1px solid var(--theme__primary--color, #ff7e4e);
      color: var(--theme__primary--color, #ff7e4e);
      font-size: 13px;
      font-weight: 500;
      width: 26px;
      height: 26px;
      margin-right: 1.5rem;
      flex-shrink: 0;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__address {
      // font-size: 14px;
      line-height: 1.43;
      // color: #516f90;
    }

    &__checkbox {
      font-size: 1.5rem;
      padding-right: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      height: 100%;

      .option-icon {
        background-color: transparent;
        height: 24px;
      }

      .fa-circle {
        background-color: #fff;
        border-radius: 50%;
      }
    }

    .Address__name {
      font-weight: bold;
    }
  }
}

@media (max-width: 599px) {
  .storeLocatorModal {
    margin-left: 0;
    margin-right: 0;

    &__dateWrapper {
      margin-top: 1rem;
    }

    &__dateWrapper {
      max-width: 250px;
    }

    .react-datepicker {
      &-wrapper,
      &__input-container {
        width: 100%;
      }
    }

    .modal-body {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .StoreCard {
      padding: 1rem;

      &__checkbox {
        padding-right: 0;
        font-size: 1.25rem;
      }
    }
  }
}
