.product-recommendations__loader,
.product-recommendations {
  margin-top: 16px;
  display: flex;
  $gap: 36px;

  &__product {
    width: calc((100% - #{$gap} * 4) / 5);
    min-height: 90px;
    border-radius: 3px;
    padding-top: 1px;
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-left: $gap;
    }

    img {
      width: 100%;
      height: 90px;
      border-radius: 3px;
      margin-bottom: 8px;
      object-fit: contain;
    }
  }
}

.product-recommendations {
  &__title {
    // font-size: 13px;
    // color: #2d3137;
    // font-weight: bold;
    margin: 16px 0 0;
  }

  &__product {
    height: unset;
    position: relative;
    z-index: 1;
    cursor: pointer;
    transition: 0.2s ease-out;

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
      z-index: -1;
      box-shadow: 0 0 rgba(0, 0, 0, 0);
      border-radius: 3px;
      transform: scale(0.95);
      transition: 0.2s ease-out;
    }

    &:hover::before {
      transform: scale(1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    strong,
    span {
      font-size: 10px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #011234;
    }

    strong {
      font-weight: 500;
      margin-bottom: 4px;
      text-transform: capitalize;
    }
  }

  &__viewCatalog {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      border: none;
      background-color: transparent;
      padding: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0d1922;
      text-decoration: underline;
      cursor: pointer;

      &:active,
      &:focus {
        outline: none;
      }

      &:active {
        transform: scale(0.99);
      }
    }
  }
}

.product-recommendations__loader {
  @keyframes fetching {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  @mixin loading-element($delay: 0s) {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 100%;
      transform: translateX(-50%);
      background: linear-gradient(
        90deg,
        rgb(243, 243, 243) 0%,
        rgb(223, 223, 223) 50%,
        rgb(243, 243, 243) 100%
      );
      animation: fetching 1s $delay alternate-reverse infinite;
    }
  }

  &__product {
    &:nth-child(1) {
      @include loading-element;
    }
    &:nth-child(2) {
      @include loading-element(0.1s);
    }
    &:nth-child(3) {
      @include loading-element(0.2s);
    }
    &:nth-child(4) {
      @include loading-element(0.3s);
    }
    &:nth-child(5) {
      @include loading-element(0.4s);
    }
  }
}

@media screen and (max-width: 880px) {
  .product-recommendations__loader,
  .product-recommendations {
    $gap: 20px;

    &__product {
      width: calc((100% - #{$gap} * 4) / 5);

      &:not(:first-child) {
        margin-left: $gap;
      }
    }
  }
}

@media screen and (max-width: $break-small) {
  .product-recommendations__loader,
  .product-recommendations {
    $gap: 20px;
    flex-wrap: wrap;
    margin-top: 0;

    &__product {
      width: calc((100% - #{$gap} * 2) / 3);
      margin-top: $gap;

      &:nth-child(4n) {
        margin-left: 0;
      }
    }
  }
}
