.variantTile {
  margin-bottom: 20px;
  border: 1px solid #eef4f9;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  &.selected::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: var(--theme__primary--color, #45ca2d);
  }

  &__notification {
    padding-top: 0px !important;
    color: #dc3545 !important;
    span {
      text-decoration: underline;
    }
  }
  &__img {
    background-color: #eef4f9;
    overflow: hidden;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    .out-of-stock {
      opacity: 0.4;
    }
    .out-of-stock-text {
      position: absolute;
      bottom: 0;
      width: 100%;
      font-size: 13px;
      font-weight: 600;
      line-height: 1.23;
      text-align: center;
      color: #ff6c6c;
      padding: 10px;
      background: rgba(255, 108, 108, 0.15);
    }
  }
  &__description {
    padding: 10px;
  }
  &__price {
    span {
      color: #516f90;
    }
    .amount {
      font-size: 13px;
    }
    .change {
      font-size: 11px;
    }
  }
  &__check {
    position: absolute;
    width: 20px;
    top: 10px;
    right: 10px;
    color: var(--theme__primary--color, #45ca2d);
  }
  &__value {
    font-size: 13px;
    font-weight: 500;
    color: #33475b;
  }
  &__message {
    font-size: 13px;
    line-height: 1.38;
    color: #7c98b6;
    padding: 10px 0px;
  }
}
