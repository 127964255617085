@import '../mixins';
$primary-color: #607180;

.schedulePickupModal {
  margin: 0 auto;

  @include date-picker();

  .modal-content {
    min-height: 100vh;
    padding: 54px;
    border: none;
    border-radius: 0;

    .modal-header,
    .modal-body {
      padding: 0;
    }

    .modal-header {
      border: none;
      display: flex;
      align-items: center;

      h5 {
        font-size: 24px;
        font-weight: bold;
        color: $primary-color;
      }

      button {
        &:active,
        &:focus {
          outline: none;
        }
      }
    }
  }

  &__description {
    margin: 5px 0 0;
    font-size: 14px;
    line-height: 1.21;
    color: $primary-color;
    max-width: 70%;
  }

  &__dateSelect {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 32px 0 28px;
    border-bottom: 1px dashed #d2dbe5;

    &--info {
      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        color: $primary-color;
      }
      p {
        margin: 5px 0 0;
        font-size: 14px;
        color: $primary-color;
      }
    }

    &--input {
      display: flex;
      align-items: center;

      &--icon {
        opacity: 0.6;
        width: 20px;
        height: 20px;
        margin: 0 20px;
        rect {
          stroke: #517090;
        }
        path {
          fill: #517090;
        }
      }

      &__picker {
        font-size: 14px;
        font-weight: bold;
        color: $primary-color;
        padding: 10px 20px;
        text-align: center;
        width: 160px;
        box-shadow: 0 0 8px 0 #eaf0f9;
        border: solid 1px #eaf0f9;
        background-color: #ffffff;

        &:hover,
        &:focus {
          outline: none;
          box-shadow: 0 0 8px 0 #5171903d;
        }

        &:disabled {
          box-shadow: none;
          background-color: #51719015;
          cursor: not-allowed;
        }
      }
    }
  }

  &__slotsContainer {
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    transition: 0.5s;

    &.show {
      pointer-events: unset;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__timeSlotSelect {
    margin: 30px 0;

    &--head {
      h5 {
        font-size: 16px;
        font-weight: 600;
        color: $primary-color;
      }
      p {
        margin: 0;
        font-size: 14px;
        color: $primary-color;
      }
    }

    &--list {
      padding: 17px 0;
      margin: 0;
      list-style: none;
      &__item {
        $offset: 20px;
        margin: 0;
        margin-left: -$offset;
        margin-right: -$offset;
        padding: 15px $offset;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          background-color: rgb(246, 247, 255);
        }

        & ~ & {
          position: relative;

          &::before {
            content: '';
            width: calc(100% - (2 * #{$offset}));
            position: absolute;
            top: 0;
            left: $offset;
            border-top: 1px dashed #d2dbe5;
          }
        }

        &--timeSlot {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: $primary-color;
        }

        &--charges {
          display: flex;
          align-items: center;

          .amount {
            font-size: 14px;
            color: $primary-color;
          }

          .option-icon {
            background-color: transparent;
            margin: 0 5px 0 25px;
          }
        }

        .selected &--timeSlot,
        .selected &--charges .amount {
          font-weight: bold;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
      padding: 15px;
      cursor: pointer;

      &.cancel {
        font-size: 16px;
      }

      &.save {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  @media (max-width: 576px) {
    .modal-content {
      padding: 20px 20px;
    }

    &__dateSelect {
      flex-direction: column;

      &--input {
        width: 100%;
        margin-top: 10px;

        &--icon {
          position: absolute;
          margin: 0 15px;
          width: 20px;
          height: 20px;
          z-index: 1;
        }

        .react-datepicker-wrapper,
        .react-datepicker__input-container {
          width: 100%;
        }

        &__picker {
          width: 100%;
          padding: 10px 20px 10px 50px;
          text-align: left;
        }
      }
    }

    &__actions {
      flex-direction: column;
      button {
        width: 100%;

        &.cancel {
          display: none;
        }
      }
    }
  }
  @media (min-width: 576px) {
    max-width: 600px;
  }
  @media screen and (max-width: 659px) {
    .modal-content {
      margin: 0 auto;
    }
  }
  @media (min-width: 992px) {
    max-width: 800px;
  }
}
