.refund-shipping {
  .address-card {
    border-radius: 2px;
    border: solid 1px #eaf0f9;
    background-color: #ffffff;
    &__title {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
      color: rgba(12, 19, 26, 0.5);
    }
  }
  .address-block {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #001234;
    margin-right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 600px) {
      button {
        min-width: 0;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    .base {
      max-width: 160px;
      @media (max-width: 767px) {
        margin-top: 1rem;
        margin-left: 15px;
      }
    }
    .adress-line-height {
      // line-height: 1.6;
    }
  }
  .base {
    border-radius: 3px;
    border: solid 1px rgba(90, 91, 255, 0.3);
    background-color: #ffffff;
    cursor: pointer;
    &.disable-button {
      opacity: 0.5;
      cursor: not-allowed;
    }
    @media screen and (max-width: $max-size) {
      max-width: 100px;
      width: 80px;
      margin-top: 1rem;
      margin-left: 15px;
    }
  }
}
