.servicesFooter {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 4px;
  padding: 20px 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  box-shadow: 0 0 32px 4px rgba(0, 0, 0, 0.06);

  button ~ button {
    margin-left: 28px;
  }

  &__continueBtn {
    height: 48px;
    width: 176px;
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (max-width: 700px) {
    padding: 15px;
  }

  @media screen and (max-width: $break-small) {
    button {
      width: unset;
    }
  }
}
