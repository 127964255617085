.select-variant {
  .variants {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0 20px;

      a {
        font-size: 14px;
      }
    }
    &__options {
      .variant-button {
        display: inline-block;
        margin-right: 10px;
        background-color: #f6fafd;
        color: #7c98b6;
        outline: none;
        cursor: pointer;
        text-transform: uppercase;
        padding: 7px 20px;
        font-size: 14px;
        border: solid 1px #f6fafd;
        border-radius: 5px;
        transition: 0.2s;
        &.selected {
          color: var(--theme__primary--color, $primaryColor);
          background-color: #ffffff;
          border-color: var(--theme__primary--color, $primaryColor);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        }

        &.not-available {
          border-color: #fff;
          background-color: #cad2da;
          color: #fff;
          box-shadow: inset 0 0px 10px rgba(0, 0, 0, 0.3);
          &.selected {
            transform: scale(1);
            background: #ff7979;
            color: #fff;
          }
        }
      }
    }
    &__text {
      padding-top: 0.75rem;
    }
  }
}
